import React from 'react'
import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components/macro'

const NoSearchResult = () => (
	<NoData>
		<FaSearchIcon /> Nothing found
	</NoData>
)

const NoData = styled.div`
	grid-column: -1 / 1;
	justify-self: center;
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 1.4em;
	opacity: 0.5;
`
const FaSearchIcon = styled(FaSearch)`
	margin-right: 10px;
`

export default NoSearchResult
