import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useAsync, useLocalStorage } from 'react-use'
import { getBugBounties } from '../apiClients/events'
import ActionPanel from '../components/ActionPanel'
import CardList from '../components/CardList'
import FeatureTitle from '../components/FeatureTitle'
import Layout from '../components/Layout'
import NoSearchResult from '../components/NoSearchResult'
import SearchInput from '../components/SearchInput'
import Spinner from '../components/Spinner'
import StoreItem, { propsFromBugBounty } from '../components/StoreItem'
import { useAppContext } from '../context'
import Entity from '../models/Entity'
import Event from '../models/Event'
import { SettingKey } from '../models/GlobalSetting'
import { PageContext } from '../models/PageContext'
import { buildMetaTitle } from '../utils/meta'

interface Props {
	pageContext: PageContext
}

export default function BugBounties({
	pageContext: { title, displayName, searchPlaceholder },
}: Props) {
	const [asList, setAsList] = useLocalStorage('bug-bounties:as-list', false)
	const [search, setSearch] = useState<string>('')
	const [bounties, setBounties] = useState<Entity<Event>[]>([])
	const [loading, setLoading] = useState(false)
	const { getSettingValue } = useAppContext()

	useAsync(async () => {
		setLoading(true)
		const response = await getBugBounties()
		if (response.data) setBounties(response.data)
		setLoading(false)
	}, [])

	const filteredBounties = bounties.filter(
		p =>
			p.attributes.name.toLowerCase().includes(search.toLocaleLowerCase()) ||
			p.attributes.markdown?.toLowerCase().includes(search.toLocaleLowerCase())
	)

	const metaTitle = getSettingValue(SettingKey.META_TITLE)

	return (
		<Layout
			breadcrumbs={[{ link: '/bug-bounties/', featureName: 'bug-bounties' }]}
			center
		>
			<Helmet>
				<title>
					{buildMetaTitle([displayName || 'Bug Bounties', metaTitle])}
				</title>
			</Helmet>
			<ActionPanel spaced={!!title}>
				{title && <FeatureTitle>{title}</FeatureTitle>}
				<SearchInput
					value={search}
					onChange={setSearch}
					placeholder={searchPlaceholder || 'Search'}
					selected={asList ? 'list' : 'card'}
					onListSelected={() => setAsList(true)}
					onCardSelected={() => setAsList(false)}
				/>
			</ActionPanel>
			<CardList asList={!!asList}>
				{loading && filteredBounties.length === 0 ? (
					<Spinner />
				) : filteredBounties.length > 0 ? (
					filteredBounties.map((bounty, i) => (
						<StoreItem
							key={bounty.id}
							{...propsFromBugBounty(bounty)}
							asListItem={asList}
							isLast={i + 1 === filteredBounties.length}
						/>
					))
				) : (
					<NoSearchResult />
				)}
			</CardList>
		</Layout>
	)
}
