import React, { useEffect } from 'react'
import { FaList, FaTh } from 'react-icons/fa'
import { useWindowSize } from 'react-use'
import styled, { css } from 'styled-components/macro'
import TextInput, { TextInputProps } from './TextInput'

interface Props extends TextInputProps {
	selected?: 'list' | 'card'
	width?: string
	onListSelected?: () => void
	onCardSelected?: () => void
}

const SearchInput = ({
	selected,
	width,
	onListSelected,
	onCardSelected,
	...rest
}: Props) => {
	const showIcons = useWindowSize().width <= 600

	useEffect(() => {
		if (!showIcons) onCardSelected?.()
	}, [showIcons])

	return (
		<Container showIcons={showIcons} width={width}>
			<SearchInputField {...rest} />
			{showIcons && (
				<>
					{onCardSelected && (
						<Card selected={selected === 'card'} onClick={onCardSelected} />
					)}
					{onListSelected && (
						<List selected={selected === 'list'} onClick={onListSelected} />
					)}
				</>
			)}
		</Container>
	)
}

interface ContainerProps {
	showIcons?: boolean
	width?: string
}

const Container = styled.div<ContainerProps>`
	display: grid;
	grid-template-columns: ${({ showIcons }) =>
		showIcons ? '1fr auto auto' : '1fr'};
	align-items: center;
	gap: 10px;
	max-width: 100%;
	${({ showIcons }) =>
		!showIcons &&
		css`
			width: 300px;
		`}
	width: ${({ width }) => width || 'auto'};
`

const SearchInputField = styled(TextInput)`
	flex: 1;
	max-width: 300px;
	padding-left: 1em;
	padding-right: 1em;
	border-radius: 100px;
	align-self: center;
`

const List = styled(FaList)<{ selected: boolean }>`
	cursor: pointer;
	transition: all 0.2s linear;
	${({ selected }) =>
		selected
			? css`
					color: ${p => p.theme.linkColor};
			  `
			: css`
					opacity: 0.3;
					color: #000;
					:hover {
						color: ${p => p.theme.linkColor};
						opacity: 1;
					}
			  `}
`

const Card = List.withComponent(FaTh)

export default SearchInput
