import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { useAppContext } from '../context'
import { SettingKey } from '../models/GlobalSetting'

interface Props {
	asList: boolean
	children: ReactNode
	className?: string
}

const CardList = ({ asList, ...rest }: Props) => {
	const { getSettingValue } = useAppContext()

	const columns = Number(getSettingValue(SettingKey.GRID_COLUMNS))

	return asList ? (
		<Simple {...rest} />
	) : (
		<Grid columns={columns ?? 3} {...rest} />
	)
}

const Simple = styled.div`
	width: 100%;
	max-width: ${p => p.theme.maxWidth};
	display: grid;
	grid-template-columns: auto 1fr auto;
	align-items: center;
	justify-items: left;
	margin: 10px 50px;
	gap: 10px 40px;
	overflow: hidden;
	@media (max-width: 700px) {
		grid-template-columns: auto 1fr 0;
		margin: 10px 5px;
		gap: 10px;
	}
`

const Grid = styled.div<{ columns: number }>`
	width: 100%;
	display: grid;
	${({ columns }) => css`
		grid-template-columns: repeat(
			auto-fill,
			minmax(${(1200 - 24 * (columns - 1)) / columns}px, 1fr)
		);
	`}
	gap: 70px 24px;
	max-width: ${p => p.theme.maxWidth};
	margin: 0 auto;
`

export default CardList
