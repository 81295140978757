import styled, { css } from 'styled-components/macro'

const ActionPanel = styled.div<{ spaced?: boolean }>`
	display: flex;
	align-items: center;
	margin-bottom: 2em;
	width: 100%;
	gap: 2em;

	${p =>
		p.spaced
			? css`
					justify-content: space-between;
			  `
			: css`
					justify-content: center;
			  `}

	@media (max-width: 800px) {
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
	}
`

export default ActionPanel
